@use 'breakpoints.scss';

.iconWithBorder {
    color: var(--headingButton);
    width: 36px;
    height: 36px;
    border: 1px solid var(--brandLight5);
    padding: 6px;
    background-color: var(--brandLight6);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.cta {
        background-color: var(--ctaLightest);
        border: 1px solid var(--ctaLight);
        color: var(--ctaMain);
    }

    &.bw {
        background-color: var(--mainBg);
        border: 1px solid var(--brandLight5);
        color: var(--mainFg);
    }

    &.small {
        width: 26px;
        height: 26px;
        padding: 4px;
        border-radius: 6px;
    }

    &.smaller {
        width: 20px;
        height: 20px;
        padding: 2px;

        color: var(--meta);
        font-size: 0.8em;

        svg {
            color: var(--meta);

        }
    }

    &.round {
        border-radius: 12px;
    }

    &.positive {
        background-color: var(--positiveGreenerLight);
        border: 1px solid var(--positiveGreener);
        width: 26px;
        height: 26px;
        color: var(--positiveGreener);
    }

    &.negative {
        background-color: var(--negativeLight);
        border: 1px solid var(--danger);
        width: 26px;
        height: 26px;
        color: var(--danger);

    }

    &.text {
        width: auto;
        padding: 8px;

        span {
            font-size: 0.9em;
            margin-left: 2px;
            white-space: nowrap;
        }

        svg {
            width: 14px;
            height: 14px;
        }
    }
}